/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:2fb003f8-bd5f-4caa-9e54-8d018df7428e",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_GbUOxPyHc",
    "aws_user_pools_web_client_id": "hrt8tiegd48cgb0j3kqvoh60j",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://m3sihbegubeirke4zymlc6kipa.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://5ze2tkksyk.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "audiotracks-cl170239-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
