import React, { Component } from "react"
import Layout from "../components/Layout/layout"
import { graphql } from 'gatsby'
import { Link } from 'gatsby'


import { isLoggedIn, logout } from '../services/auth'

import Amplify from 'aws-amplify'
import awsmobile from '../aws-exports'
Amplify.configure(awsmobile)

class BooksPage extends Component {
  state = {
    smallScreen: false,
    modal: { name: "" },
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 840 })
  }

  openModal(e) {
    this.setState({ modal: e })
    document.getElementById("modal").style.display = "block"
  }

  closeModal() {
    document.getElementById("modal").style.display = "none"
  }

  render() {
    let workData = this.props.data.allBooksJson.nodes[0]
    let workItems = []
    let i = 0
    workData.work_items.forEach(item => {
      workItems.push(
	  			<div class="p-2 bg-white-200 flex justify-center items-center">
						<div class="max-w-1xl bg-white border-0 border-white-100 p-5 tracking-wide">
							<img alt="mountain" class="h-80"  src={item.image}></img>
							<h4 class="text-xl font-semibold p-8">{item.name}</h4>
							<p class="font-sans font-extralight text-justify">{item.description}</p>	
					</div>
				</div>
      )
      i++
    })
    return (
      <Layout page={"books"}>
        <h1 className="title p-3">{workData.title}</h1>
        <div className="text">{workData.text}</div>
        
           
        <div
          className={
            this.state.smallScreen
              ? "grid-container-small"
              : "work-grid-container"
          }
        >
        <center>
          {workItems}
        </center>
        </div>
        <div id="modal" className="modal" onClick={this.closeModal}>
          <div
            className={
              this.state.smallScreen ? "modal-content-small" : "modal-content"
            }
          >
            <span className="modal-close">&times;</span>
            <div className="modal-grid-container">
              	<img
                  src={this.state.modal.image}
                  alt={this.state.modal.name}
                  className="modal-image ml-8"
                >
                </img>
                <p></p>
                <div><h2 className="modal-title">{this.state.modal.name}</h2></div>
                <p></p>
                <p className="modal-text"><div dangerouslySetInnerHTML={{__html:this.state.modal.description}} /></p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default BooksPage

export const query = graphql`
  query {
    allBooksJson {
      nodes {
        id
        title
        text
        work_items {
          name
          description
          image
        }
      }
    }
  }
`
